/**
 *
 * https://github.com/diffusion-fi/v2-periphery/blob/main/scripts/config/config.ts
 *
 * When changing this also update: cypress/integration/contracts.ts
 *
 */

const PERIPHERY_TESTNET = {
  factory: '0x4DDf0fa98B5f9Bd7Cb0645c25bA89A574fe9Be8c',
  weth9: '0x04a72466De69109889Db059Cb1A4460Ca0648d9D',
  router: '0x169C06b4cfB09bFD73A81e6f2Bb1eB514D75bB19',
  mockUSDC: '0xc51534568489f47949A828C8e3BF68463bdF3566',
  mockCANTO: '0xf1361Dc7DFB724bd29FE7ade0CdF9785F2Bc20E6',
  mockATOM: '0x9832169B33DC5777D3d28572f35E0a537Ff7A04C',
  mockOSMOSIS: '0x1dccd8025688e39C72f2539C6f00d77bd6678425',
  multicall2: '0x0e356B86FA2aE1bEB93174C18AD373207a40F2A3',
  LHS: '0x7c21d6A51d6f591A95470f1F262C9c804c4CEAc3',
  RHS: '0xD3607915d934576EcdC389E7DBc641097fd5A0dE',
  testerAddress: '0x1662BfeA0Af3515baf9DAb3f0961Dc26DD35202B',
  //0x851e5cE9fa409B731f980a5E00FA889b58D9037D
  // 0xA2c659531B15bFf2556Ea7E12D477D3C8761ACD9
  //0x95BF964f113a75a3974E8164105e6e5A8D743b87
  // 0x62154D72C202f04CA50a3Ba5630052D0348f337A
  rewardToken: '0x7e806D59528F6Fa7CCcAdb4821Dd42551113DEFc',
  secondaryRewardToken: '0x9AC19677BD6B1a3ba046C33f4D2f1952cA0e9a13',
  miniChef: '0x0fCee557E3eB94913e202eF91314f14298591a61',
  complexRewarderTime: '0x2916d2e0B675e6993250f2DB9764Cd7fD5379C04',
  diffusion: '',
}

const MAINNET_PERIPHERY = {
  factory: '0x1BB9cf4e63CD2DAb8741c334f525350AB2Ee2c96',
  weth9: '0x826551890Dc65655a0Aceca109aB11AbDbD7a07B',
  router: '0xD463786E7d54Fc570A92982157E15130e469dcD8',
  multicall2: '0xfCD3842f85ed87ba2889b4D35893403796e67FF1',
  //
  minichef: '0x0000000000000000000000000000000000000000',
  diffusion: '0xfbc0FC368BC57E6C203cf7fd823fC10c6d274E6C',
}

export const MAINNET = {
  ...MAINNET_PERIPHERY,
  diffusionbar: '0x0000000000000000000000000000000000000000',
  airdrop: '0xb9A52744213eA63D57F389622e1d569Bb4705207',
}

export const TESTNET = {
  ...PERIPHERY_TESTNET,
  airdrop: '0x2F7Ad6172388aED2017FBfA1631724F172360Ab1',
  diffusionbar: '0x2314D451a1A2519501119f105dd1D65D0CE4E93b',
}
